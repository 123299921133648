<template>
  <iframe
    class="frame"
    :src="(workaroundCategory || category).frameUrl"
  />
</template>

<script>
  export default {
    inheritAttrs: false,
    props: [
      'category',
      'breadcrumb',
      'splitviewMode',
    ],
    data() {
      return {
        // TODO: Should be deleted after REST-API has been adjusted
        // TODO: for loading properties directly (frameUrl)
        workaroundCategory: null,
      }
    },
    async created() {
      if (this.splitviewMode) {
        this.workaroundCategory = await this.$api.findCategory(this.category.id)
      }
    },
    inject: [
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .frame {
    @apply
      flex
      w-full
      h-full
      items-center
      justify-center;
  }
</style>